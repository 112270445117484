var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
function initWaitForArgs(source) {
    // Set defaults
    return Object.assign({
        intervalMs: 50,
        timeoutMs: 5000,
        timeoutMessage: 'Timeout on waiting for condition',
        onTimeout: e => console.warn(e)
    }, source);
}
/**
 * Awaits for condition
 * @param condition condition check function
 * @param args
 * @throws Will throw error on timeout
 */
export function waitFor(condition, args) {
    args = initWaitForArgs(args);
    return new Promise((resolve, reject) => {
        const onResolve = () => {
            // console.debug(`Condition met, location: ${window.location}`);
            resolve();
        };
        if (condition()) {
            onResolve();
            return;
        }
        let cancel = false;
        const interval = setInterval(() => {
            // console.debug(`Checking condition, location: ${window.location}`);
            if (!condition()) {
                return;
            }
            clearInterval(interval);
            cancel = true;
            onResolve();
        }, args.intervalMs);
        setTimeout(() => {
            clearInterval(interval);
            if (cancel) {
                return;
            }
            // Don't create the error immediately to avoid it being logged incorrectly
            const error = () => new Error(args.timeoutMessage);
            reject(error());
            if (args.onTimeout) {
                args.onTimeout(error());
            }
        }, args.timeoutMs);
    });
}
/**
 * Awaits for condition wihtout throwing timeout error
 * @param condition condition check function
 * @param args
 */
export function tryWaitFor(condition, args) {
    return __awaiter(this, void 0, void 0, function* () {
        args = initWaitForArgs(args);
        try {
            yield waitFor(condition, args);
        }
        catch (error) {
            if (error.message === args.timeoutMessage && args.onTimeout) {
                args.onTimeout(error);
                return;
            }
            WhistleOut.Rollbar.warning(error);
        }
    });
}
