import { onArrayPush } from './array';
export function pushDataLayerItem(item) {
    window.woDataLayer = window.woDataLayer || [];
    window.woDataLayer.push(item);
}
export function pushDataLayerItemWidget(item) {
    pushDataLayerItem(item);
}
function getWidgetType() {
    const pathName = window.location.pathname;
    const matches = /^\/widgets\/(\w+)([/|?].+)?/i.exec(pathName);
    return matches ? matches[1] : undefined;
}
export function initDataLayer() {
    var _a;
    window.woDataLayer = (_a = window.woDataLayer) !== null && _a !== void 0 ? _a : [];
    // Set default values, reset unused (not explicitly set) parameters
    onArrayPush(window.woDataLayer, items => {
        var _a, _b, _c, _d, _e;
        for (let index = 0; index < items.length; index++) {
            const item = items[index];
            (_a = item['duration']) !== null && _a !== void 0 ? _a : (item['duration'] = undefined);
            (_b = item['error']) !== null && _b !== void 0 ? _b : (item['error'] = undefined);
            (_c = item['widget_type']) !== null && _c !== void 0 ? _c : (item['widget_type'] = undefined);
            (_d = item['widget_placement']) !== null && _d !== void 0 ? _d : (item['widget_placement'] = undefined);
            (_e = item['widget_type']) !== null && _e !== void 0 ? _e : (item['widget_type'] = getWidgetType());
        }
    });
}
