/* globals process */
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { broadbandApi } from '../../../Broadband/js/redux/broadbandApi';
import rootReducer from './rootReducer';
const createStore = () => {
    const store = configureStore({
        preloadedState: {
            broadband: {}
        },
        reducer: rootReducer,
        devTools: process.env.NODE_ENV !== 'production',
        middleware: getDefaultMiddleware => getDefaultMiddleware().concat(broadbandApi.middleware)
    });
    // optional, but required for refetchOnFocus/refetchOnReconnect behaviors
    // see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
    setupListeners(store.dispatch);
    return store;
};
export const store = createStore();
export const { dispatch, getState, subscribe } = store;
