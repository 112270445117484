import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
export const broadbandApi = createApi({
    reducerPath: 'broadband/api',
    baseQuery: fetchBaseQuery({ baseUrl: '/' }),
    endpoints: builder => ({
        getSearchResults: builder.query({
            query: ({ baseUrl, queryString }) => {
                return queryString.length ? `${baseUrl}?${queryString}` : baseUrl;
            }
        }),
        getTransactUrl: builder.query({
            query: args => {
                const queryParams = new URLSearchParams(args);
                return `/Ajax/Broadband/Product/GetTransactUrl/?${queryParams.toString()}`;
            }
        })
    })
});
