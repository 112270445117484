import { createSelector, createSlice } from '@reduxjs/toolkit';
import { produce } from 'immer';
import defaultTo from 'lodash/defaultTo';
import isEqual from 'lodash/isEqual';
const initialState = {};
const sliceName = 'broadband/search';
const slice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        disableMaximumResultLimits(state) {
            state.criteria.common.enforceMaximumResultLimits = false;
            state.settings.enforceAjax = true;
        },
        hideFeaturedResults(state) {
            state.criteria.common.includeFeaturedResults = false;
            state.settings.enforceAjax = true;
        },
        includeNbnConnections(state, action) {
            const nbnConnectionTypes = action.payload;
            if (Array.isArray(nbnConnectionTypes)) {
                state.criteria.connectionTypes.values = [
                    ...state.criteria.connectionTypes.values,
                    ...nbnConnectionTypes
                ];
            }
            else {
                state.criteria.connectionTypes.values.push(nbnConnectionTypes);
            }
        },
        resetBundles(state) {
            state.criteria.bundles = {};
        },
        searchError(state) {
            return state;
        },
        searchSuccess(state, action) {
            const result = action.payload;
            return produce(state, p => {
                // TODO: Remove 'values' property and use simple array
                // Remove unused isAll and isEmpty fields
                //p.search.criteria.common.suppliers = result.criteria.common.suppliers.values;
                p.settings.suppliers = result.suppliers;
                p.settings.coverage = result.coverage;
                p.settings.unavailableConnectionTypes = result.unavailableConnectionTypes;
                p.settings.allConnectionsSelected = result.allAvailableConnectionsSelected;
                p.settings.hideSupplierFilter = result.hideSupplierFilter;
                p.settings.resultsSearchCriteria = result.criteria;
            });
        },
        updateAddress(state, action) {
            if (isEqual(state.criteria.common.address, action.payload)) {
                return state;
            }
            const baseCriteria = state.settings.embeddedMode ? state.settings.defaultSearchCriteria : state.criteria;
            state.settings.enforceAjax = false;
            state.criteria = produce(baseCriteria, draft => {
                var _a;
                draft.common.address = action.payload;
                const defaultSuppliers = state.settings.defaultSuppliers;
                draft.common.suppliers.values = defaultSuppliers && defaultSuppliers.length ? defaultSuppliers : [];
                draft.common.tab = WhistleOut.getSiteConfiguration().broadbandConfig.defaultTab;
                draft.connectionTypes.values = [];
                draft.bundles = {};
                const isSingleProvider = ((_a = state.criteria.common.suppliers.values) === null || _a === void 0 ? void 0 : _a.length) === 1;
                draft.common.enforceMaximumResultLimits = !isSingleProvider;
                // Preserve original criteria
                draft.speed = state.criteria.speed;
                draft.data = state.criteria.data;
            });
        },
        //changeBundles
        updateBundles(state, action) {
            const bundles = action.payload;
            state.criteria.bundles.homePhone = bundles.homePhone;
            state.criteria.bundles.tv = bundles.tv;
            state.criteria.bundles.lineRental = bundles.lineRental;
            state.criteria.bundles.mobilePhone = bundles.mobilePhone;
        },
        updateConnectionTypes(state, action) {
            state.settings.allConnectionsSelected = null;
            state.criteria.connectionTypes.values = Array.isArray(action.payload) ? action.payload : [action.payload];
        },
        // changeContractType
        updateContractType(state, action) {
            const { contractType, hide } = action.payload;
            if (contractType === 'prepaid') {
                return state;
            }
            const contractTerms = defaultTo(parseInt(contractType), null);
            if (hide === true) {
                if (state.criteria.common.contractTerms && state.criteria.common.contractTerms.length > 1) {
                    state.criteria.common.contractTerms = state.criteria.common.contractTerms.filter(c => c !== contractTerms);
                }
                else {
                    state.criteria.common.contractTerms = state.settings.allContractTerms.filter(c => c !== contractTerms);
                }
            }
            else {
                state.criteria.common.contractTerms = [contractTerms];
            }
        },
        updateCriteria(state, action) {
            if (isEqual(state, action.payload)) {
                return state;
            }
            state.settings.allConnectionsSelected = null;
            state.criteria = produce(action.payload, draft => {
                if (state.criteria.modemShortUrl !== draft.modemShortUrl) {
                    draft.common.tab = null;
                }
            });
        },
        // changeData
        updateData(state, action) {
            if (action.payload === state.criteria.data) {
                return state;
            }
            state.criteria.data = action.payload;
            state.criteria.common.results.productLabel = null;
        },
        //changeModem
        updateModem(state, action) {
            if (action.payload === state.criteria.modemShortUrl) {
                return;
            }
            state.criteria.modemShortUrl = action.payload;
            state.settings.enforceAjax = true;
        },
        // planSummarySupplierChanged
        updatePlanSummarySupplier(state, action) {
            const { supplier, maxResults } = action.payload;
            state.criteria.common.suppliers.values = supplier ? [supplier] : [];
            state.criteria.common.enforceMaximumResultLimits = supplier ? false : true;
            state.criteria.common.tab = '';
            state.criteria.common.results.maximumNumberOfResults = maxResults;
            state.settings.enforceAjax = true;
        },
        // changeSort
        updateSort(state, action) {
            const { expression, isAscending } = action.payload;
            state.criteria.common.resultsSortExpression = expression;
            state.criteria.common.resultsSortAscending = isAscending;
            state.settings.enforceAjax = true;
        },
        // changeSpeed
        updateSpeed(state, action) {
            if (action.payload === state.criteria.speed) {
                return state;
            }
            state.criteria.speed = action.payload;
            state.criteria.common.results.productLabel = null;
        },
        // changeSpend
        updateSpend(state, action) {
            const { minValue, maxValue } = action.payload;
            const commonCriteria = state.criteria.common;
            if ((minValue === commonCriteria.minimumSpend || (minValue === -1 && !commonCriteria.minimumSpend)) &&
                (maxValue === commonCriteria.maximumSpend || (maxValue === -1 && !commonCriteria.maximumSpend))) {
                return state;
            }
            commonCriteria.maximumSpend = maxValue;
            commonCriteria.minimumSpend = minValue;
        },
        // changeSupplier
        updateSuppliers(state, action) {
            state.criteria.common.suppliers = {
                values: Array.isArray(action.payload) ? action.payload : [action.payload]
            };
            state.settings.enforceAjax = true;
        },
        // changeTab(value) {
        updateTab(state, action) {
            state.criteria.common.tab = action.payload;
        },
        widenResults(state, action) {
            const options = action.payload;
            if (options.reduceData) {
                state.criteria.data = 0;
            }
            if (options.increasePriceRange) {
                state.criteria.common.maximumSpend = -1;
                state.criteria.common.minimumSpend = 0;
            }
            if (options.includeAllContractLengths) {
                state.criteria.common.contractTerms = [];
            }
            if (options.includeAllSuppliers === true) {
                state.criteria.common.suppliers = { values: [] };
            }
            if (options.reduceSpeed === true) {
                state.criteria.speed = 0;
            }
            if (options.noPeakData === true) {
                state.criteria.peakDataOnly = false;
            }
            if (options.includeMoreConnectionTypes === true) {
                state.criteria.connectionTypes = { values: [] };
            }
            if (options.noDealsOnly === true) {
                state.criteria.common.includeOffersWithCampaignOnly = false;
            }
            if (options.includeAllPrepaidExpiry === true) {
                state.criteria.prepaidExpiry = null;
            }
            if (options.removeSelectedModem === true) {
                state.criteria.modemShortUrl = null;
                state.criteria.simOnly = false;
            }
            if (options.noUploadCounted === true) {
                state.criteria.uploadNotCounted = false;
            }
            if (options.noStaticIp === true) {
                state.criteria.staticIp = false;
            }
            if (options.noBundling === true) {
                state.criteria.bundles.tv = null;
                state.criteria.bundles.homePhone = null;
                state.criteria.bundles.lineRental = null;
                state.criteria.bundles.mobilePhone = null;
            }
            if (options.sortBy === true) {
                state.criteria.common.resultsSortExpression = null;
                state.criteria.common.resultsSortAscending = true;
            }
        }
    }
});
export const actions = slice.actions;
const extraSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(slice.actions.searchSuccess, (state, action) => {
            slice.reducer(state, actions.updateCriteria(action.payload.criteria));
        });
    }
});
export default function broadbandReducer(state, action) {
    return extraSlice.reducer(slice.reducer(state, action), action);
}
export const selectCriteria = createSelector((state) => state, state => state.broadband.search.criteria);
