import { createSlice } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import modal from './modalSlice';
import search from './searchSlice';
export const mapSearchModelToState = (searchModel) => {
    const state = {
        criteria: searchModel.state.criteria,
        settings: {
            coverageUrl: searchModel.coverageUrl,
            searchUrl: searchModel.searchUrl,
            resultsSearchCriteria: searchModel.state.criteria,
            pagedResultsUrl: searchModel.pagedResultsUrl,
            suppressCoverageCheck: searchModel.suppressCoverageCheck,
            embeddedMode: searchModel.embeddedMode,
            enforceAjax: searchModel.embeddedMode,
            allConnectionsSelected: searchModel.state.allConnectionsSelected,
            coverage: searchModel.state.coverage,
            hideSupplierFilter: searchModel.state.hideSupplierFilter,
            showMaxUpfrontSlider: searchModel.state.showMaxUpfrontSlider,
            suppliers: searchModel.state.suppliers,
            unavailableConnectionTypes: searchModel.state.unavailableConnectionTypes,
            defaultSearchCriteria: searchModel.defaultSearchCriteria,
            defaultPrepaidExpiryDays: searchModel.defaultPrepaidExpiryDays
        }
    };
    if (searchModel.filters) {
        state.settings.allContractTerms = searchModel.filters.allContractTerms;
        state.settings.defaultSuppliers = searchModel.filters.defaultSuppliers;
    }
    return state;
};
const broadbandSlice = createSlice({
    name: 'broadband',
    initialState: {},
    reducers: {
        init(state, action) {
            state.search = mapSearchModelToState(action.payload);
        }
    }
});
const features = combineReducers({
    search,
    modal
});
export default function broadbandReducer(state, action) {
    const featuresState = features(state, action);
    return broadbandSlice.reducer(featuresState, action);
}
export const actions = broadbandSlice.actions;
