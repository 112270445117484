export function replaceQueryString(url, newQuery) {
    const regex = /\?(.*)$/gi;
    let newUrl = url.replace(regex, '');
    if (newQuery[0] !== '?')
        newUrl += '?';
    newUrl += newQuery;
    return newUrl;
}
// TODO: Don't extend prototype, use a separate helper function instead
export function extendPrototype() {
    if (!String.prototype.replaceQueryString) {
        // ReSharper disable once NativeTypePrototypeExtending
        /* eslint-disable-next-line no-extend-native */
        String.prototype.replaceQueryString = function (newQuery) {
            return replaceQueryString(this, newQuery);
        };
    }
}
