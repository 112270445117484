import { createSlice } from '@reduxjs/toolkit';
const sharedSlice = createSlice({
    name: 'shared',
    initialState: {
        addressCookie: {
            initialiesdWith: null
        }
    },
    reducers: {
        addressCookieInit(state, action) {
            state.addressCookie.initialiesdWith = action.payload.address;
        }
    }
});
export default sharedSlice.reducer;
export const actions = sharedSlice.actions;
