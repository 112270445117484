import { createSelector, createSlice } from '@reduxjs/toolkit';
import { produce } from 'immer';
import includes from 'lodash/includes';
import isEqual from 'lodash/isEqual';
const initialState = {};
const modalSlice = createSlice({
    name: 'broadband/modal',
    initialState,
    reducers: {
        showModal(state, action) {
            // Should we just select the first one by default?
            state.activeTab = action.payload.tabName || 'spend';
            state.criteria = produce(action.payload.criteria, draft => {
                return draft;
            });
            state.isOpen = true;
        },
        hideModal(state) {
            state.isOpen = false;
            // Reset search criteria
            state.criteria = {};
        },
        selectTab(state, action) {
            state.activeTab = action.payload;
        },
        updateCriteria(state, action) {
            if (isEqual(state.criteria, action.payload)) {
                return;
            }
            state.criteria = action.payload;
        },
        updateData(state, action) {
            state.criteria.data = action.payload;
        },
        updatePeakDataOnly(state, action) {
            state.criteria.peakDataOnly = action.payload;
        },
        updateSpeed(state, action) {
            state.criteria.speed = action.payload;
        },
        updateSpend(state, action) {
            const { min, max } = action.payload;
            state.criteria.common.minimumSpend = min;
            state.criteria.common.maximumSpend = max;
        },
        updateUpfront(state, action) {
            state.criteria.common.maximumUpfront = action.payload;
        },
        updateSelectedSuppliers(state, action) {
            const { keys, customerType } = action.payload;
            state.criteria.common.suppliers.values = keys;
            state.criteria.common.customerType = customerType;
        },
        updateCustomerType(state, action) {
            state.criteria.common.customerType = action.payload;
        },
        updateContractTerms(state, action) {
            const { selected, all } = action.payload;
            state.criteria.common.contractTerms = selected && selected.length === all.length ? [] : selected;
        },
        updateAdvancedOptions(state, action) {
            const options = action.payload;
            state.criteria.common.maxResultsPerSupplier = includes(options, 'single-result') ? 1 : null;
            state.criteria.staticIp = includes(options, 'static-ip');
            state.criteria.uploadNotCounted = includes(options, 'upload-not-counted');
            state.criteria.common.showLessResults = includes(options, 'less-results');
        },
        updatePrepaidExpiry(state, action) {
            state.criteria.prepaidExpiry = action.payload;
        },
        updateCurrentSupplier(state, action) {
            state.criteria.common.currentSupplierShortUrl = action.payload;
        },
        updateIncludeDealsOnly(state, action) {
            state.criteria.common.includeOffersWithCampaignOnly = action.payload;
        },
        updatePhoneBundle(state, action) {
            state.criteria.bundles.homePhone = action.payload;
        },
        updatePhoneCallsBundle(state, action) {
            state.criteria.bundles.homePhoneCalls = action.payload;
        },
        updateTvBundle(state, action) {
            state.criteria.bundles.tv = action.payload;
        },
        updateTvChannels(state, action) {
            state.criteria.bundles.tvChannels = action.payload;
        },
        updateLineRentalBundle(state, action) {
            state.criteria.bundles.lineRental = action.payload;
        },
        updateMobileBundle(state, action) {
            state.criteria.bundles.mobilePhone = action.payload;
        },
        updateModem(state, action) {
            const { modemShortUrl, simOnly } = action.payload;
            state.criteria.modemShortUrl = modemShortUrl;
            state.criteria.simOnly = simOnly;
        },
        updateConnectionTypes(state, action) {
            state.criteria.connectionTypes.values = action.payload;
        },
        addressChanged(state) {
            // Reset suppliers and bundles criteria
            state.criteria.common.suppliers = {
                values: []
            };
            state.criteria.common.customerType = null;
            state.criteria.bundles = {};
        }
    }
});
export default modalSlice.reducer;
export const actions = modalSlice.actions;
export const selectModal = createSelector((state) => state === null || state === void 0 ? void 0 : state.broadband, p => p.modal);
