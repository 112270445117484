var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export const rollbar = {
    instance: {},
    config: {
        captureIp: false
    },
    init() {
        return __awaiter(this, void 0, void 0, function* () {
            const Rollbar = (yield import('rollbar')).default;
            this.instance = new Rollbar({
                accessToken: this.config.token,
                captureUncaught: false,
                captureUnhandledRejections: false,
                captureIp: this.config.captureIp,
                payload: {
                    environment: this.config.env
                }
            });
            this.instance.configure({
                scrubFields: ['apiKey', 'password']
            });
        });
    }
};
