var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import once from 'lodash/once';
import { waitFor } from '../../utils/timer';
const isInitialised = () => {
    return !!wo$.fn.selectpicker;
};
export const initBootstrapSelect = once(() => __awaiter(void 0, void 0, void 0, function* () {
    if (isInitialised()) {
        return;
    }
    yield import('./bootstrap-select.nomodule');
    yield waitFor(isInitialised);
    const context = wo$.fn.selectpicker.Constructor;
    context.BootstrapVersion = '3.3.6';
    context.DEFAULTS.iconBase = 'fa';
    context.DEFAULTS.tickIcon = 'fa-check';
}));
