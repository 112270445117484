export const EmailMySearch = {
    onSubmitClick: function (e) {
        const localContent = e.data.content;
        const email = localContent.find('input[data-email-address]');
        if (!e.data.emailMySearch.validateEmail(email, localContent)) {
            return;
        }
        const listId = localContent.find('[data-listid]').data('listid');
        const isSubscribeToNewsLetter = localContent.find('input[type="checkbox"]').is(':checked');
        const data = {
            email: email.val(),
            listId: listId,
            searchUrl: window.location.href,
            isSubscribeToNewsLetter: isSubscribeToNewsLetter,
            token: wo$('#turnstile-response').val()
        };
        localContent.find('.fa-spinner').show();
        wo$.ajax({
            url: '/Marketing/SendVerificationEmail',
            data: data,
            type: 'POST',
            beforeSend: function () {
                localContent.find('button[data-save-search-submit]').prop('disabled', true);
                localContent.find('[data-invalid-email], [data-error]').hide();
            },
            success: function (data) {
                if (data.IsVerificationEmailSent) {
                    const successMessage = localContent
                        .find('[data-success-message]')
                        .data('message')
                        .replace('CustomerEmailAddress', email.val());
                    localContent.find('[data-success-message]').html(successMessage);
                    localContent.find('[data-invalid-email], [data-error], [data-formrow]').hide();
                    localContent.find('[data-success]').show();
                }
                else if (data.IsVerifiedAlready) {
                    localContent.find('[data-invalid-email], [data-error], [data-formrow]').hide();
                    localContent.find('[data-verified-already]').show();
                }
                else {
                    localContent.find('[data-error]').show();
                }
            },
            error: function () {
                localContent.find('[data-invalid-email]').hide();
                localContent.find('[data-error]').show();
            },
            complete: function () {
                localContent.find('.fa-spinner').hide();
                localContent.find('button[data-save-search-submit]').prop('disabled', false);
                if (WhistleOut.turnstileId && window.turnstile) {
                    window.turnstile.reset(WhistleOut.turnstileId);
                }
            }
        });
    },
    modalInit: function (initContent) {
        this.modalContent = initContent;
        initContent.find('[data-invalid-email]').hide();
        initContent.on('show.bs.modal', () => {
            initContent.find('[data-formrow]').show();
            initContent.find('[data-invalid-email], [data-error], [data-success]').hide();
            initContent.find('input[type="checkbox"]').prop('checked', false);
            initContent.find('input[data-email-address]').val('');
            initContent.find('div[data-formrow]').show();
            initContent.find('div[data-email-success]').hide();
            initContent.find('div[data-subscribe-success]').hide();
            initContent.find('[data-verified-already]').hide();
            initContent
                .find('button[data-save-search-submit]')
                .off('click')
                .on('click', { content: initContent, emailMySearch: this }, this.onSubmitClick);
        });
    },
    adInit: function (initContent, submitButton) {
        this.adContent = initContent;
        initContent.find('[data-invalid-email]').hide();
        if (submitButton) {
            submitButton.off('click').on('click', { content: initContent, emailMySearch: this }, this.onSubmitClick);
        }
    },
    validateEmail: function (email, content) {
        const config = WhistleOut.getSiteConfiguration();
        const regex = new RegExp(config.regex.email);
        if (!email.val() || !regex.test(email.val())) {
            content.find('[data-invalid-email]').show();
            return false;
        }
        return true;
    }
};
